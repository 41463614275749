import { MapRecordType, StrictKeys } from 'types/global';
import {
  AbsolutePositionType,
  AutoOrManualInterface,
  BorderSettingsInterface,
  ByType,
  ColorDefaultSettingsInterface,
  ConnectedCellPaddingSettings,
  ContainerSettingsInterface,
  FictionalDataInterface,
  FieldNameInterface,
  FilterElementMode,
  FilterInfluenceInterface,
  GroupInterface,
  IdInterface,
  IsMultipleInterface,
  IsRealDataInterface,
  LimitInterface,
  ModelIdInterface,
  NameFromDatabaseInterface,
  NameInterface,
  OverflowInterface,
  PaddingSettingsInterface,
  PageIdInterface,
  PositionConfigSettingsInterface,
  ProjectIdInterface,
  SqlDataInterface,
  SqlDataRequestInterface,
  ViewFontSettingsInterface,
} from 'types/store';
import { FullQueryProps } from 'utils/SQL/generateSQL';
import { RLSInterface, TextSettingInterface } from 'types/types';
import {
  BackgroundSettingsInterface,
  ColorValuesByThemeType,
  DefaultPropertiesInterface,
  IsActiveInterface,
  IsBlockInterface,
  IsVisibleInterface,
  LimitSettingInterface,
  StyleContainerSettingsInterface,
  TextPositionInterface,
  VariablesSettingsInterface,
} from '../visualisations/types';
import { SortingValueEnum } from 'components/shared/SortingPanel/types';

export interface FilterNameSettingsInterface {
  nameSettings: NameFromDatabaseInterface & FieldNameInterface & FilterElementMode;
}

export type SingleFilterType = 'single';
export type MultipleFilterType = 'multiple';
export type DateFilterType = 'date';
export type FilterType = SingleFilterType | MultipleFilterType | DateFilterType;
export const FilterTypes = ['single', 'multiple', 'date'];

export interface FilterTypeInterface<FilterType> {
  type: FilterType;
}

export interface IsGlobalInterface {
  isGlobal: boolean;
}

export interface SingleFilterDataInterface {
  selectedData: string[];
}

export interface SingleFilterSettingsInterface {
  paddings: ConnectedCellPaddingSettings;
}

export type TypeStateButtonType = 'defaultState' | 'active' | 'blocked';

export interface SizeButtonsSettingInterface extends IsActiveInterface {
  width: number;
  height: number;
}

export type CornerRoundingRadiusInterface = {
  isActive: boolean;
  all: number;
  leftTop: number;
  leftBottom: number;
  rightBottom: number;
  rightTop: number;
};

export interface BorderLineStyle extends IsActiveInterface {
  position: AbsolutePositionType;
  thickness: number;
  fontColor: ColorValuesByThemeType | null;
  widthSize: AutoOrManualInterface;
  cornerRoundingRadius: CornerRoundingRadiusInterface;
}

export interface ElementDesignButtonsSettingsInterface extends BorderSettingsInterface {
  // stateButtonType: TypeStateButtonType;
  fillColor: ColorDefaultSettingsInterface;
  cornerRoundingRadius: CornerRoundingRadiusInterface;
  textPropertiesSettings: DefaultPropertiesInterface;
  borderLineStyle: BorderLineStyle;
}

export type AllElementDesignButtonsSettingsMap = Record<TypeStateButtonType, ElementDesignButtonsSettingsInterface>;

export interface ElementDesignSettingsDataInterface extends PaddingSettingsInterface {
  stateButtonType: TypeStateButtonType;
  sizeButtonsSetting: SizeButtonsSettingInterface;
  fontFamilySettings: ViewFontSettingsInterface;
  elementDesignSettings: AllElementDesignButtonsSettingsMap;
}

export type ChevronLocationsType = 'defaultLocations' | 'rightLocations';

export interface ButtonSettingsInterface {
  chevronLocations: ChevronLocationsType;
  textPropertiesSettings: DefaultPropertiesInterface;
}

export interface ViewSettingsDataInterface {
  elementDesignSettingsData: ElementDesignSettingsDataInterface;
  buttonSettings: ButtonSettingsInterface;
}

export interface DateFilterDataInterface {
  byType: ByType;
  startDate: string | null;
  endDate: string | null;
}

export interface DefaultSelectedValuesInterface {
  selectedValues: string[];
}

export interface DateFilterSelectedValuesInterface {
  selectedValues: DateFilterDataInterface;
}

export interface SingleFilterSortingStatusInterface {
  sortingStatus?: SortingValueEnum;
}

export interface HintInterface {
  hint: TextSettingInterface;
}

export type FilterInterface<FilterType, FilterData = object, SettingsData = object, ViewSettingsData = object> = IdInterface &
  PageIdInterface &
  IsRealDataInterface &
  /* TODO: Make FictionalData as dependency from filter type */
  FictionalDataInterface &
  IsGlobalInterface &
  FilterNameSettingsInterface &
  ModelIdInterface &
  ContainerSettingsInterface &
  FilterInfluenceInterface &
  SqlDataInterface &
  IsBlockInterface &
  IsVisibleInterface &
  LimitInterface &
  NameInterface &
  PaddingSettingsInterface &
  IsMultipleInterface &
  IsAlwaysOpenInterface &
  SingleFilterSortingStatusInterface &
  HintInterface &
  OverflowInterface &
  GroupInterface &
  PositionConfigSettingsInterface &
  FilterTypeInterface<FilterType> & {
    position: TextPositionInterface;
    styleContainerSettings: StyleContainerSettingsInterface;
  } & {
    [Key in keyof FilterData]: FilterData[Key];
  } & {
    [Key in keyof SettingsData]: SettingsData[Key];
  } & {
    [Key in keyof ViewSettingsData]: ViewSettingsData[Key];
  } & {
    events: CommonEventsSettingsFilterInterface;
  };

export interface CommonEventsSettingsFilterInterface {
  variablesSettings: VariablesSettingsInterface;
}

export type SingleFilterInterface = FilterInterface<
  SingleFilterType,
  SingleFilterDataInterface,
  SingleFilterSettingsInterface,
  ViewSettingsDataInterface
>;

export type MultipleFilterInterface = FilterInterface<MultipleFilterType, ViewSettingsDataInterface>;
export type DateFilterInterface = FilterInterface<DateFilterType, ViewSettingsDataInterface>;

export type FilterDataType = SingleFilterInterface | MultipleFilterInterface | DateFilterInterface;

export type FilterMapDataType = {
  single: SingleFilterInterface;
  multiple: MultipleFilterInterface;
  date: DateFilterInterface;
};

export type FilterMapInterface = MapRecordType<FilterDataType>;
export type FilterIdsByPageInterface = MapRecordType<Set<string>>;

export type EnabledFilterInterface<
  FilterType,
  SelectedValues extends { selectedValues: unknown } = DefaultSelectedValuesInterface,
> = IdInterface &
  PageIdInterface &
  IsGlobalInterface &
  IsRealDataInterface &
  FilterNameSettingsInterface &
  FilterInfluenceInterface &
  ModelIdInterface &
  FilterTypeInterface<FilterType> & {
    link: 'filter' | 'visualisation';
    linkId: string;
  } & {
    [Key in keyof SelectedValues]: SelectedValues[Key];
  } & {
    incisionRequest?: string | null;
  };

export type SingleEnabledFilterInterface = EnabledFilterInterface<SingleFilterType>;
export type MultipleEnabledFilterInterface = EnabledFilterInterface<MultipleFilterType>;
export type DateEnabledFilterInterface = EnabledFilterInterface<DateFilterType, DateFilterSelectedValuesInterface>;

export type EnabledFilterDataType = SingleEnabledFilterInterface | MultipleEnabledFilterInterface | DateEnabledFilterInterface;

export type EnabledFiltersMapInterface = MapRecordType<EnabledFilterDataType>;
export type EnabledFilterIdsByPageInterface = MapRecordType<Set<string>>;

export type FilterValueType = {
  value: string;
  count: number;
};

export type FiltersValuesType = FilterValueType[];

export interface EnabledFiltersDependencyInterface extends IdInterface, IsRealDataInterface {
  type: EnabledFilterDataType['type'];
  values: EnabledFilterDataType['selectedValues'];
  fieldName: string;
  incisionRequest?: string | null;
}

/* Store SLice Type */

export interface FilterStateInterface {
  filtersData: { filtersValues: MapRecordType<FiltersValuesType> };
  filters: FilterMapInterface;
  serverStateOfFilters: FilterMapInterface | null;
  filtersByPages: FilterIdsByPageInterface;
  filtersByProject: FilterMapInterface;
  enabledFilters: EnabledFiltersMapInterface;
  serverStateOfEnabledFilters: EnabledFiltersMapInterface | null;
  enabledFiltersByPages: EnabledFilterIdsByPageInterface;
  filtersLoading: boolean;
  filtersValuesLoadingList: MapRecordType<boolean>;
  filtersErrorsList: MapRecordType<string | null>;
  enabledFiltersLoadingList: MapRecordType<boolean>;
  alreadyLoadedContent: {
    filters: Set<string>;
    enabledFilters: Set<string>;
  };
}

/* Action Types */

export enum FilterActionsTypes {
  ADD_FILTER = 'ADD_FILTER',
  REMOVE_FILTER_ID = 'REMOVE_FILTER_ID',
  UPDATE_FILTER = 'UPDATE_FILTER',
  ENABLE_FILTER = 'ENABLE_FILTER',
  UPDATE_ENABLED_FILTER = 'UPDATE_ENABLED_FILTER',
  DISABLE_FILTER = 'DISABLE_FILTER',
  CLIMBING_INCISION = 'CLIMBING_INCISION',
  LOAD_FILTER_VALUES = 'LOAD_FILTER_VALUES',
  LOAD_FILTERS = 'LOAD_FILTERS',
  LOAD_ENABLED_FILTERS = 'LOAD_ENABLED_FILTERS',
  LOAD_FILTERS_BY_PAGE_ID = 'LOAD_FILTERS_BY_PAGE_ID',
  LOAD_FILTERS_BY_PROJECT_ID = 'LOAD_FILTERS_BY_PROJECT_ID',
  LOAD_FILTERS_FROM_SNAPSHOT = 'LOAD_FILTERS_FROM_SNAPSHOT',
  LOAD_ENABLED_FILTER_BY_PAGE_ID = 'LOAD_ENABLED_FILTER_BY_PAGE_ID',
  LOAD_GLOBAL_ENABLED_FILTER_BY_PAGE_ID = 'LOAD_GLOBAL_ENABLED_FILTER_BY_PAGE_ID',
  LOAD_ENABLED_FILTER_FROM_SNAPSHOT = 'LOAD_ENABLED_FILTER_FROM_SNAPSHOT',
  REMOVE_FILTER_VALUES = 'REMOVE_FILTER_VALUES',
  REMOVE_FROM_FILTER_LOADING_LIST = 'REMOVE_FROM_FILTER_LOADING_LIST',
  CLEAR_FILTERS_STORE = 'CLEAR_FILTERS_STORE',
}

/* Payloads */

export interface AddNewFilterPayload extends PageIdInterface, IdInterface, NameInterface {}

export type EnableFilterActionPayload<EnabledFilterInterface extends EnabledFilterDataType = EnabledFilterDataType> = Omit<
  EnabledFilterInterface,
  'id' | 'pageId'
>;

export interface UpdateEnabledFilterPayload<EnabledFilterInterface extends EnabledFilterDataType = EnabledFilterDataType>
  extends IdInterface {
  data: Partial<EnableFilterActionPayload<EnabledFilterInterface>>;
}

export interface UpdateFilterPayload<FilterInterface extends FilterDataType = FilterDataType> extends IdInterface {
  data: Partial<Omit<FilterInterface, 'pageId' | 'type' | 'id'>>;
}

export interface LoadFieldsResponse {
  data: FiltersValuesType;
  isOverLimit: boolean;
  conditionColorsObject: ColorValuesByThemeType[];
}

export interface LoadFieldsPayload
  extends IdInterface,
    StrictKeys<ModelIdInterface>,
    Pick<FullQueryProps, 'whereQuery'>,
    Required<Pick<FullQueryProps, 'fromQuery'>>,
    ProjectIdInterface {
  fieldName: string;
  searchString: string;
  rls?: RLSInterface;
}

export interface GenerateFilterSqlStringInterface {
  sqlData: SqlDataRequestInterface;
  whereQuery: string | undefined;
  searchString?: string;
  limit: LimitSettingInterface | undefined;
  sortingStatus?: string | null | undefined;
  fromQuery: string;
  fieldName: string | null;
  isDataFilter: boolean;
  withoutValueAndCount?: boolean;
  originLimit?: boolean;
  backgroundSettings?: BackgroundSettingsInterface;
}

export interface LoadFieldsResponseInterface {
  value?: Array<string | number | null>;
  count?: Array<number | null>;
  backgroundConditionValue?: string[];
}

export interface IsAlwaysOpenInterface {
  isAlwaysOpen?: boolean;
}

export type LoadFiltersOutput = Pick<FilterStateInterface, 'filtersByPages' | 'filters'> & {
  filtersByProject?: FilterStateInterface['filtersByProject'];
};

export type LoadEnabledFiltersOutput = Pick<FilterStateInterface, 'enabledFiltersByPages' | 'enabledFilters'>;

export type LoadGlobalEnabledFiltersOutput = LoadEnabledFiltersOutput;

export enum FilterTypeEnum {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
  DATE = 'date',
}

export enum GroupTypeEnum {
  GROUP = 'group',
}
