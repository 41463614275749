import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { debounce } from 'lodash';
import { raisingLabelMixin } from 'constants/styles';
import { NoopType, ReactChildrenType } from 'types/global';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { FlexContainer } from 'styles/FlexContainer';
import { Button } from 'modules/ui/index';
import { ClearIcon } from 'assets/icons/withContainer';
import { delay } from './constants';
import { Hint } from 'modules/ui/Hint';
import { useClickHandler } from 'utils/utils';
import isUndefined from 'lodash/isUndefined';

export interface IProps extends Partial<InputWrapperProps> {
  type?: 'text' | 'password' | 'number' | 'search' | 'time';
  name: string;
  doubleClick?: boolean;
  description?: string;
  placeholder?: string;
  value?: string | number;
  defaultValue?: string | number;
  onChange?: (e: any) => void;
  onClick?: NoopType;
  autofocus?: boolean;
  ref?: any;
  useDebounce?: boolean;
  label?: string;
  afterSlot?: ReactChildrenType;
  beforeSlot?: ReactChildrenType;
  onClear?: NoopType;
  widthContainer?: string;
  maxLength?: number;
  HintChildrenText?: ReactChildrenType;
  formattingValue?: (value: string) => string;
  padding?: string;
  doubleClickActive?: boolean;
  disableInteractiveTooltipHint?: boolean;
}

const TextField: FC<IProps> = ({
  type = 'text',
  name,
  label,
  placeholder = '',
  width = '212px',
  widthContainer,
  heightSize = 'normal',
  disabled = false,
  value,
  description,
  needBackground = true,
  needBorderBottom = true,
  error,
  ref,
  onChange,
  onClick,
  useDebounce,
  onClear,
  afterSlot,
  beforeSlot,
  HintChildrenText,
  formattingValue,
  doubleClickActive,
  disableInteractiveTooltipHint = true,
  ...props
}) => {
  const [localValue, setLocalValue] = useState(value);
  const [isActive, setIsActive] = useState(false);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const displayedValue = formattingValue && localValue ? formattingValue(localValue.toString()) : localValue;

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleDoubleClick = () => setIsActive(true);
  const handleBlur = () => setIsActive(false);

  const handleClick = useClickHandler(() => {}, handleDoubleClick, 50);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedChange = useCallback(debounce(onChange ? onChange : () => ({}), delay), [onChange]);

  /* TODO:We need to get rid of the debounce */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedClear = useCallback(debounce(onClear ? onClear : () => ({}), delay), [onClear]);

  const onLocalChange = (e: any) => {
    const { value } = e.target;

    setLocalValue(value);

    // const formattedValue = formattingValue && localValue ? formattingValue(localValue.toString()) : localValue;
    //TODO передавать не event, а formattedValue
    if (useDebounce) {
      onChange && onDebouncedChange(e);
    } else {
      onChange && onChange(e);
    }
  };

  const hasLabel = !!label;

  useEffect(() => {
    if (isActive && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isActive]);

  return (
    <FlexContainer
      position="relative"
      width={widthContainer || '100%'}
      flexDirection="column"
      gap="8px"
      onClick={(e) => {
        doubleClickActive && handleClick(e);
      }}
    >
      <InputWrapper
        width={width}
        heightSize={heightSize}
        disabled={disabled}
        needBackground={needBackground}
        needBorderBottom={needBorderBottom}
        hasLabel={hasLabel}
        hasHover={doubleClickActive}
        error={error}
      >
        {afterSlot}
        <Input
          needBackground={needBorderBottom}
          type={type}
          name={name}
          placeholder={placeholder}
          value={displayedValue}
          ref={isUndefined(ref) ? inputRef : ref}
          onChange={onLocalChange}
          onClick={onClick}
          heightSize={heightSize}
          showLabel={hasLabel}
          disabled={disabled}
          autofocus={doubleClickActive ? isActive : null}
          onBlur={doubleClickActive ? handleBlur : undefined}
          style={doubleClickActive ? { pointerEvents: isActive && doubleClickActive ? 'auto' : 'none' } : undefined}
          {...props}
        />
        {beforeSlot}
        {hasLabel && <Label>{label}</Label>}
        {afterSlot && onClear && value && (
          <FlexContainer alignItems="center" height="100%">
            <Button
              heightSize="small"
              iconSize="big"
              leftIcon={<ClearIcon />}
              needBackground={false}
              onClick={onDebouncedClear}
            />
          </FlexContainer>
        )}
      </InputWrapper>
      {error && (
        <PrimaryTextSpan width={width} color={`var(${ColorVarsEnum.Alert})`} lineHeight="12px" fontSize="10px">
          {error}
        </PrimaryTextSpan>
      )}
      {description && (
        <PrimaryTextSpan width={width} color={`var(${ColorVarsEnum.Level_2})`} lineHeight="12px" fontSize="10px">
          {description}
        </PrimaryTextSpan>
      )}
      {HintChildrenText && (
        <HintContainer>
          <Hint disableInteractiveTooltipHint={disableInteractiveTooltipHint}> {HintChildrenText}</Hint>
        </HintContainer>
      )}
    </FlexContainer>
  );
};

export default TextField;

interface InputProps {
  heightSize: 'small' | 'normal';
  needBackground: boolean;
  showLabel?: boolean;
  padding?: string;
}

const Input = styled.input<InputProps>`
  display: flex;
  flex: 1 1 0;
  width: 100%;
  height: 100%;
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: ${(props) => (props.heightSize === 'normal' ? '14px' : '12px')};
  line-height: 100%;
  padding: 0 8px;
  ${({ padding }) => padding && `padding: ${padding}`};

  color: ${`var(${ColorVarsEnum.Level_1})`};
  border: unset;

  ::placeholder {
    color: ${`var(${ColorVarsEnum.Level_2})`};
  }

  :focus {
    outline: none;
    box-shadow: unset;
    background: transparent;
  }

  background: transparent;

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  -moz-appearance: textfield;

  :-webkit-autofill,
  :-webkit-autofill:hover,
  :-webkit-autofill:focus {
    -webkit-text-fill-color: ${`var(${ColorVarsEnum.Level_1})`};
    -webkit-box-shadow: 0 0 0 1000px
      ${(props) => (props.needBackground ? `var(${ColorVarsEnum.Level_6_application})` : `transparent`)} inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  ${({ showLabel }) =>
    showLabel &&
    css`
      &:not([value=""]) ~ label,
      input &:focus ~ label {
      {
        ${raisingLabelMixin};
      }
    `}
`;

export const Label = styled.label`
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  transition: all 0.3s ease;
  color: ${`var(${ColorVarsEnum.Level_2})`};
`;

interface InputWrapperProps extends Pick<InputProps, 'heightSize' | 'needBackground'> {
  width: string;
  needBorderBottom: boolean;
  error?: string;
  disabled: boolean;
  hasLabel?: boolean;
  hasHover?: boolean;
}

const HintContainer = styled.div`
  position: absolute;
  right: 0;
`;

const InputWrapper = styled.div<InputWrapperProps>`
  position: relative;
  display: flex;
  align-items: flex-end;
  width: ${(props) => props.width};
  height: ${(props) => (props.heightSize === 'normal' ? '30px' : '24px')};
  background: ${(props) => (props.needBackground ? `var(${ColorVarsEnum.Level_6_application})` : 'transparent')};
  padding: 0 2px;
  margin-top: ${({ hasLabel }) => (hasLabel ? '18px' : '0')};
  border: unset;
  border-bottom: ${(props) =>
    props.needBorderBottom
      ? props.error
        ? `1px solid var(${ColorVarsEnum.Alert})`
        : `1px solid var(${ColorVarsEnum.Level_4})`
      : '1px solid transparent'};
  transition: box-shadow 200ms, background 200ms;

  ${({ hasHover, disabled }) =>
    !hasHover &&
    css`
      &:hover {
        box-shadow: ${() => (disabled ? `inset 0 0 0 1px var(${ColorVarsEnum.Level_3})` : 'unset')};
        cursor: text;
      }
    `}
  :focus-within {
    outline: none;
    box-shadow: unset;
    border-bottom: ${(props) =>
      props.error ? `1px solid var(${ColorVarsEnum.Alert})` : `1px solid var(${ColorVarsEnum.Level_4})`};
    height: ${(props) => (props.heightSize === 'normal' ? '30px' : '24px')};
    background: ${`var(${ColorVarsEnum.Level_6_application})`};
  }
`;
