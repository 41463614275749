import styled from 'styled-components';

export const PickerItemWrapper = styled.div`
  display: flex;
  flex-direction: 'column';
  gap: 4px;
  align-items: center;
  justify-content: flex-start;
  min-height: 48px;

  :hover {
    span {
      display: none;
    }
  }
`;
