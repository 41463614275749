import React, { FC, MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import type { PrimaryTextType } from 'styles/TextsElements';

export interface IButton extends PrimaryTextType {
  text?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onMouseDown?: MouseEventHandler<HTMLButtonElement>;
  width?: string;
  leftIcon?: any;
  type?: 'submit' | 'reset' | 'button';
  needBackground?: boolean;
  heightSize?: 'small' | 'normal';
  iconSize?: 'normal' | 'big';
  disabled?: boolean;
  selected?: boolean;
  active?: boolean;
}

export const Button: FC<IButton> = ({
  text = '',
  onClick,
  onMouseDown,
  width = 'unset',
  leftIcon = null,
  type = 'button',
  needBackground = true,
  heightSize = 'normal',
  iconSize = 'normal',
  disabled = false,
  selected = false,
  active = false,
  ...props
}) => {
  return (
    <ButtonContainer
      onClick={onClick}
      onMouseDown={onMouseDown}
      width={width}
      leftIcon={leftIcon}
      type={type}
      needBackground={needBackground}
      heightSize={heightSize}
      iconSize={iconSize}
      text={text}
      disabled={disabled}
      selected={selected}
      active={active}
      {...props}
    >
      {leftIcon}
      {text}
    </ButtonContainer>
  );
};

export default Button;

const ButtonContainer = styled.button<IButton>`
  width: ${(props) => (props.width ? props.width : 'unset')};
  height: ${(props) => (props.heightSize === 'normal' ? '30px' : '24px')};
  font-size: ${(props) => props.fontSize || (props.heightSize === 'normal' ? '14px' : '12px')};
  line-height: 100%;
  border: unset;
  outline: none !important;
  padding: ${(props) => {
    if (props.text) {
      return props.leftIcon ? '0 10px 0 0' : '0 10px';
    } else {
      return '0';
    }
  }};
  border-radius: 2px;
  background: ${(props) => (props.needBackground ? `var(${ColorVarsEnum.Level_2_btn})` : 'transparent')};
  color: ${({ active, color }) => (active ? `var(${ColorVarsEnum.Accent})` : color || `var(${ColorVarsEnum.Level_1})`)};
  white-space: nowrap;
  cursor: ${(props) => (props.disabled ? 'unset' : 'pointer')};
  transition: all 200ms;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: ${(props) => props.fontStyle || 'normal'};
  font-weight: ${(props) => props.fontWeight || 400};
  text-decoration: ${(props) => props.textDecoration || 400};
  letter-spacing: ${(props) => props.letterSpacing};
  font-family: ${(props) => props.fontFamily || 'inherit'};

  outline: 0;

  ${(props) =>
    !props.disabled &&
    css`
      &:hover {
        background: ${props.needBackground ? `var(${ColorVarsEnum.Level_1_hover_btn})` : `var(${ColorVarsEnum.Level_2_btn})`};
      }
    `}
  svg {
    path,
    circle,
    rect {
      stroke: ${`var(${ColorVarsEnum.Level_2})`};
      transition: all 200ms;
      ${(props) => {
        if (props.selected && !props.text) return `stroke: var(${ColorVarsEnum.Accent}); transition: all 200ms;`;
      }}
    }

    ${(props) => {
      if (props.heightSize === 'normal') {
        switch (props.iconSize) {
          case 'normal':
            return 'width: 18px; height: 18px; margin: 0 6px;';
          case 'big':
            return 'width: 24px; height: 24px; margin: 0 3px;';
        }
      } else {
        switch (props.iconSize) {
          case 'normal':
            return 'width: 14px; height: 14px; margin: 0 5px;';
          case 'big':
            return 'width: 18px; height: 18px; margin: 0 3px;';
        }
      }
    }}
  }
`;
