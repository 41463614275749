import { ElementContainerSettings, GroupContainerSettings, MainContainerSettings } from 'modules/settingsContainer';
import React from 'react';
import { FlexContainer } from 'styles/FlexContainer';
import { PaletteColorSettings } from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/PaletteColorSettings';
import {
  ActiveColorInterface,
  AddColorType,
  ChangeColorsType,
  ChangeColorType,
  ChangeGroupNameType,
  CopyGroupType,
  DeleteColorType,
  DeleteGroupType,
} from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/PaletteColorSettings/types';
import { PaletteItemInterface } from 'store/reducers/palettes/types';

import { MovePaletteGroupInterface } from 'modules/workspace/components/panelSettingsApp/tabsContent/PalettesTab/types';
import { NoopType, NoopValueType } from 'types/global';
import { HexAndHLSColorInterface } from 'types/store';

interface PalettesContentProps {
  activePalette: PaletteItemInterface;
  activeColor: ActiveColorInterface;
  setActiveColor: NoopValueType<ActiveColorInterface>;
  synchronizationGroupMode: boolean;
  onDeleteColor: DeleteColorType;
  onDeleteGroup: DeleteGroupType;
  onAddColor: AddColorType;
  onCopyGroup: CopyGroupType;
  onColorChange: ChangeColorType;
  onColorsChange: ChangeColorsType;
  onGroupNameChange: ChangeGroupNameType;
  onMovePaletteGroup: NoopValueType<MovePaletteGroupInterface>;
}

export const PalettesContent: React.FC<PalettesContentProps> = ({
  activePalette,
  activeColor,
  setActiveColor,
  synchronizationGroupMode,
  onDeleteColor,
  onDeleteGroup,
  onAddColor,
  onCopyGroup,
  onColorChange,
  onColorsChange,
  onGroupNameChange,
  onMovePaletteGroup,
}) => {
  return (
    <FlexContainer marginTop="100px" width="100%">
      <MainContainerSettings>
        <GroupContainerSettings>
          <ElementContainerSettings>
            <PaletteColorSettings
              palette={activePalette}
              activeColor={activeColor}
              onChangeActiveColor={setActiveColor}
              synchronizationGroupMode={synchronizationGroupMode}
              onDeleteColor={onDeleteColor}
              onDeleteGroup={onDeleteGroup}
              onAddColor={onAddColor}
              onCopyGroup={onCopyGroup}
              onColorChange={onColorChange}
              onColorsChange={onColorsChange}
              onGroupNameChange={onGroupNameChange}
              onMovePaletteGroup={onMovePaletteGroup}
            />
          </ElementContainerSettings>
        </GroupContainerSettings>
      </MainContainerSettings>
    </FlexContainer>
  );
};
