import { NoopType, ReactChildrenType } from 'types/global';
import { PrimaryTextSpan } from 'styles/TextsElements';
import { ColorVarsEnum } from 'enums/ColorVarsEnum';
import { IconWrapper } from 'modules/ui/wrappers/IconWrapper';
import { DeleteIcon } from 'assets/icons/withContainer';
import React from 'react';
import { PickerItemWrapper } from './styles';
import { FlexContainer } from 'styles/FlexContainer';

interface PickerWrapperProps {
  name?: string | number;
  onDelete?: NoopType;
  children: ReactChildrenType;
  isRemoveDeleteIcon?: boolean;
}

export const PickerWrapper = ({ name, onDelete, children, isRemoveDeleteIcon }: PickerWrapperProps) => {
  return (
    <PickerItemWrapper>
      {children}
      {name && (
        <PrimaryTextSpan lineHeight="14px" fontSize="14px" color={`var(${ColorVarsEnum.Level_1})`}>
          {name}
        </PrimaryTextSpan>
      )}
      {!isRemoveDeleteIcon && (
        <FlexContainer onClick={onDelete}>
          <IconWrapper
            hoverColorVar={ColorVarsEnum.Level_3}
            colorVar={ColorVarsEnum.Level_3}
            iconWidth="15px"
            iconHeight="13px"
            Icon={DeleteIcon}
          />
        </FlexContainer>
      )}
    </PickerItemWrapper>
  );
};
