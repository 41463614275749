import React, { StrictMode } from 'react';
import { Provider } from 'react-redux';
import { persistor, store } from 'store';
import { Global } from '@emotion/react';
import { ApplyTheme } from 'styles/ColorVars';
import { SnackbarUtilsConfigurator } from 'services/Snackbar';
import { PersistGate } from 'redux-persist/integration/react';
import { AppModals } from 'components/AppModals';
import { SnackbarErrorWrapper, SnackbarProviderContainer } from 'constants/styles';
import { Container, globalStyles } from 'styles/Global';
import { RouterProvider } from 'react-router-dom';
import { router } from './providers/router';
import { SnackbarErrorIcon } from 'assets/icons/snackbar';

const App = () => {
  return (
    <StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SnackbarProviderContainer
            preventDuplicate
            maxSnack={3}
            autoHideDuration={5000}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            iconVariant={{
              error: (
                <SnackbarErrorWrapper>
                  <SnackbarErrorIcon />
                </SnackbarErrorWrapper>
              ),
            }}
          >
            <SnackbarUtilsConfigurator />
            <AppModals />
            <Container className="App">
              <ApplyTheme>
                <RouterProvider router={router} />
              </ApplyTheme>
            </Container>
          </SnackbarProviderContainer>
        </PersistGate>
      </Provider>
      <Global styles={globalStyles} />
    </StrictMode>
  );
};

export default App;
