import { RadioSelectedItem } from 'components/RadioSelected/types';
import { ColorValueType, PaletteValuesByThemeType } from 'modules/settingsContainer/ColorPicker/types';
import { ExportType, SQLRequestInterface } from 'modules/settingsContainer/ContextMenuWrapper/types';
import { SortingInterface } from 'modules/visualisations/Table/visualisation/types';
import { Limit } from 'node-sql-parser';
import { DateFilterDataInterface } from 'store/reducers/filters/types';
import { MapRecordType, StrictKeys } from 'types/global';
import {
  AbsolutePositionSettingType,
  AutoEndManualType,
  BackgroundViewSettingsInterface,
  BoardPositionConfigInterface,
  BorderSettingsInterface,
  ColorDefaultSettingsInterface,
  ConnectedCellPaddingSettings,
  ContainerSettingsInterface,
  DesignOptionInterface,
  FictionalDataInterface,
  FieldNameInterface,
  GroupInterface,
  IdInterface,
  IsRealDataInterface,
  LoadingInterface,
  ModelIdInterface,
  NameFromDatabaseInterface,
  NameInterface,
  PaddingsVisualisationInterface,
  PageIdInterface,
  PositionConfigSettingsInterface,
  PositionSettingType,
  ProportionsImageSettingType,
  ShadowSettingsInterface,
  ShowBackgroundInterface,
  SqlLimitInterface,
  StartAndEndInterface,
  ViewFontSettingsInterface,
} from 'types/store';
import { AbsolutePositionType, RightAndLeftType, TextStylesInterface, TopAndBottomType } from 'types/styles';
import {
  ColorSettingsIncision,
  ColorSettingsIndicator,
  GradientColorSettings,
  RLSInterface,
  TextSettingColorInterface,
  TextSettingInterface,
} from 'types/types';
import { VariableExpandedStatusType } from '../projectPages/types';

export type VisualisationValuesInterface<T = null> = Record<string, Array<number | T | null> | Array<string | null> | undefined>;

export interface VisualisationDataInterface {
  visualisationValues?: VisualisationValuesInterface;
}

export type VisualisationDataMapInterface = MapRecordType<VisualisationDataInterface>;

export interface IsShowInterface {
  isShow: boolean;
}

export interface IsActiveInterface {
  isActive: boolean;
}

export interface AutoOrManualInterface {
  type: AutoEndManualType;
  value: number;
}

export type OrientationType = 'horizontal' | 'vertical';

export interface OrientationInterface {
  orientation: OrientationType;
}

export interface ShowTipsInterface {
  showTips: boolean;
}

export interface AlignmentInterface {
  horizontal: PositionSettingType;
  vertical: PositionSettingType;
}

export interface DefaultPropertiesInterface extends TextStylesInterface {
  isActiveStyle: boolean;
  isActiveFontColor: boolean;
  isActiveBackgroundColor: boolean;
  opacity: number;
  padding: ConnectedCellPaddingSettings;
  fontColorBy: ColorByInterface;
  backgroundColorBy: ColorByInterface;
  changeFillColor: boolean;
  alignment: AlignmentInterface;
  fontFamilySettings: ViewFontSettingsInterface;
}

export type Formatting = 'numerical' | 'percent' | 'money' | 'fraction' | 'exponential';
export type Currency = 'RUB' | 'EUR' | 'USD';
export type ColorValuesByThemeType = MapRecordType<ColorValueType>;

export type MetaFormattingType = {
  money: Currency;
};

export interface FormattingItem {
  formattingType: Formatting;
  meta: MetaFormattingType;
}

interface FormattingEditText extends IsActiveInterface {
  text: string;
}

export interface FormatsInterface {
  formattingItem: FormattingItem;
  editText: FormattingEditText;
  numberOfZeros: number;
  numeric: boolean;
}

export interface FormattingInterface extends IsShowInterface {
  formats: FormatsInterface;
}

export type TransitionModeType = 'here' | 'new';

export enum TransitionModeEnum {
  HERE = 'here',
  NEW = 'new',
}

export type LinkSelectionModeType = 'table' | 'value';

export enum LinkSelectionModeEnum {
  TABLE = 'table',
  VALUE = 'value',
}

export interface SqlValuesInterface {
  fieldName: string | null;
  sqlCondition: string | null;
}

export interface LinkSelectionModeInterface {
  type: 'value' | 'table';
  value: SqlValuesInterface;
}

export interface MakeHyperLinksInterface {
  isActive: boolean;
  isFiltering: boolean;
  transitionMode: TransitionModeType;
  source: SourceInterface;
}

export interface ImagesSourceValuesInterface extends SqlValuesInterface {
  textLink: string | null;
}

export interface AlignmentAndPositionImageInterface extends AlignmentInterface {
  absolutePositionSetting: AbsolutePositionSettingType;
}

export interface SourceInterface {
  type: SourceTypes;
  value: ImagesSourceValuesInterface;
}

export interface ImagesSettingsInterface {
  isActive: boolean;
  source: SourceInterface;
  size: {
    horizontal: number;
    vertical: number;
  };
  alignmentAndPosition: AlignmentAndPositionImageInterface;
  textIndent: number;
}

export interface AliasInterface {
  alias: string;
}

export type ColorAndImageByType = 'default' | 'condition' | 'value' | 'valueSpecific' | 'rule';

export enum ColorAndImageByEnum {
  DEFAULT = 'default',
  CONDITION = 'condition',
  VALUE = 'value',
  VALUE_SPECIFIC = 'valueSpecific',
  VALUE_SPECIFIC_STEPS = 'valueSpecificSteps',
  RULE = 'rule',
}

export type GradientByType =
  | 'default'
  | 'valueSpecificGradient'
  | 'valueSpecificSteps'
  | 'valueGradient'
  | 'value'
  | 'valueSteps'
  | 'rule'
  | 'condition';

export interface DefaultByInterface<Type extends string> {
  type: Type;
  isActive?: boolean;
}

export interface ColorByItem {
  value: ColorValuesByThemeType | null;
}

export interface SpecificColorByItem {
  value: ColorValuesByThemeType | null;
  numericalValue: number | null;
  type?: 'min' | 'max';
  percent: string;
}

export interface ColorByConditionInterface extends AliasInterface {
  colors: ColorByItem[];
  sqlCondition: string | null;
}

export interface ColorByRuleCondition extends AliasInterface {
  rules: ColorByRuleInterface[];
  defaultColor: ColorValuesByThemeType | null;
  sqlCondition: string | null;
}

export interface ColorByRuleInterface {
  id: string | null;
  rule: string | null;
  firstValue: string | null;
  secondValue: string | null;
  elementColor: ColorValuesByThemeType | null;
  fieldName: string | null;
  customRequest: string | null;
  operationType: VisualisationOperationTypesType | null;
  isActive: boolean;
}

export interface ColorByValueInterface extends AliasInterface {
  colors: MapRecordType<ColorByItem[]> | null;
  valueType?: ElementSpecificValueEnum;
  borderType?: BorderSpecificValueEnum;
}

export interface ColorByValueSpecificInterface extends AliasInterface {
  colors: MapRecordType<SpecificColorByItem[]> | null;
  borderType: BorderSpecificValueEnum;
  valueType: ElementSpecificValueEnum;
  direction: SortOrderEnum;
}

export interface ColorByInterface extends DefaultByInterface<ColorAndImageByType> {
  byCondition: ColorByConditionInterface;
  byValue: ColorByValueInterface;
  byValueSpecific: ColorByValueSpecificInterface;
  byRule: ColorByRuleCondition;
}

export interface GradientColorByInterface extends DefaultByInterface<GradientByType> {
  byCondition: ColorByConditionInterface;
  byValueGradient: ColorByValueInterface;
  byValueSteps: ColorByValueInterface;
  byValueSpecificGradient: ColorByValueInterface;
  byValueSpecificSteps: ColorByValueInterface;
  byValue: ColorByValueInterface;
  byRule: ColorByRuleCondition;
}

interface GradientBackgroundByValueSettingsInterface {
  gradientBackgroundByValueSettings: GradientColorByInterface;
}

export interface ColorBySettingsInterface {
  colorBySettings: ColorByInterface;
}

export interface ColorByValueSettingsInterface {
  colorByValueSettings: ColorByInterface;
}

export interface BackgroundByValueSettingsInterface {
  backgroundByValueSettings: ColorByInterface;
}

export interface ImageByValueInterface {
  images: ImageByItem[];
}

export type ImageValues = { linkImage: string };

export interface ImageByItem {
  value: ImageValues | null;
}

export interface ImageByConditionInterface extends AliasInterface {
  images: ImageByItem[];
  sqlCondition: string | null;
}

export interface ImageByInterface extends DefaultByInterface<ColorAndImageByType> {
  byCondition: ImageByConditionInterface;
  byValue: ImageByValueInterface;
}

export interface SelectItemInterface extends AliasInterface {
  selectSql: string;
}

export interface ServiceSelectValuesInterface {
  serviceSelectValues: SelectItemInterface[];
}

export interface VariablesSelectValuesInterface {
  variablesSelectValues: SelectItemInterface[];
}

export interface ImagesSelectValuesInterface {
  imagesSelectValues: SelectItemInterface[];
}

export interface LimitSettingInterface extends IsActiveInterface {
  value: number;
}

export interface OverflowPositionInterface {
  label: string;
  value: OverflowValueEnum | string;
}

export enum OverflowValueEnum {
  SEQUENTIALLY = 'sequentially',
  HORIZONTALLY = 'horizontally',
  VERTICALLY = 'vertically',
}

export type SortingStatusType = 'ASC' | 'DESC' | null;

export type TextType = 'text';
export type VisualisationTypeWithActiveIncisionId =
  | 'tree'
  | 'pie'
  | 'lineAndBar'
  | 'waterfall'
  | 'bubble'
  | 'frame'
  | 'geometricShapes';
export type VisualisationType = 'heatmap' | 'table' | 'text' | VisualisationTypeWithActiveIncisionId;
export type VisualisationTypeType = VisualisationType | TextType;

export enum VisualisationTypeEnum {
  TREE = 'tree',
  PIE = 'pie',
  LINEANDBAR = 'lineAndBar',
  WATERFALL = 'waterfall',
  BUBBLE = 'bubble',
  HEATMAP = 'heatmap',
  TABLE = 'table',
  TEXT = 'text',
  FRAME = 'frame',
  GEOMETRICSHAPES = 'geometricShapes',
}

export interface StyleContainerSettingsInterface
  extends ShadowSettingsInterface,
    BackgroundViewSettingsInterface,
    BorderSettingsInterface,
    ShowBackgroundInterface {}

export interface DefaultViewSettingsInterface extends ContainerSettingsInterface, GroupInterface {
  styleContainerSettings: StyleContainerSettingsInterface;
  header?: TextSettingInterface | TextSettingColorInterface;
  description?: TextSettingInterface | TextSettingColorInterface;
  hint?: TextSettingInterface;
  headerPosition?: PositionSettingType;
  name?: string;
}

export interface DefaultBackgroundImagesSettingsInterface {
  images?: string;
}

export type DefaultBackgroundImagesSettingsType = DefaultBackgroundImagesSettingsInterface | null;

export type StrictDefaultViewSettingsInterface = Required<DefaultViewSettingsInterface>;

export interface DefaultSqlDataInterface {
  filterAndGroupRequest: string;
}

export interface IsGroupRowInterface {
  isGroup?: boolean;
}

export interface DefaultIncisionSettingsInterface extends NameFromDatabaseInterface, CustomRequestInterface, IsGroupRowInterface {
  emptyValues: IndicatorEmptyValuesInterface;
}

export interface DefaultIncisionInterface
  extends VisualisationIdInterface,
    VisualisationFieldNameInterface,
    FictionalDataInterface {
  settings: DefaultIncisionSettingsInterface;
}

export interface DefaultIncisionWithSqlDataInterface extends DefaultIncisionInterface {
  colors?: PaletteValuesByThemeType | null;
  emptyValues?: IndicatorEmptyValuesInterface;
}

export interface DefaultIndicatorSettingsInterface extends NameFromDatabaseInterface {
  formatting: FormattingInterface;
}

export interface DefaultIndicatorInterface
  extends VisualisationIdInterface,
    VisualisationFieldNameInterface,
    VisualisationOperationTypeInterface {
  settings: DefaultIndicatorSettingsInterface;
  emptyValues: IndicatorEmptyValuesInterface;
}

export interface IndicatorEmptyValuesInterface {
  isEmptyValue: boolean;
  value: string;
}

export interface IsDrillDownInterface {
  isDrillDown?: boolean;
}

export interface DefaultDataSettingsInterface
  extends IsRealDataInterface,
    ModelIdInterface,
    ActiveIncisionIdInterface,
    IsDrillDownInterface {
  incisions: DefaultIncisionWithSqlDataInterface[];
  indicators: DefaultIndicatorWithSqlDataInterface[];
  limit: LimitSettingInterface;
  hasAllGroupIncision?: boolean;
  indicatorsStackSum?: DefaultIndicatorWithSqlDataInterface[];
}

export interface DefaultIndicatorWithSqlDataInterface extends DefaultIndicatorInterface {
  color?: ColorValuesByThemeType | null;
}

export type DefaultDataSettingsWithActiveIncisionIdInterface = DefaultDataSettingsInterface;

export type DefaultDataSettingsType = DefaultDataSettingsInterface;

export type VisualisationOperationTypesType = 'sum' | 'count' | 'avg' | 'min' | 'max' | 'other' | 'countUnique' | 'text';

export enum ColorOperatorEnum {
  DEFAULT = 'default',
  SINGLE = 'single',
}

export enum VisualisationOperationTypesEnum {
  SUM = 'sum',
  COUNT = 'count',
  COUNTUNIQUE = 'countUnique',
  AVG = 'avg',
  MIN = 'min',
  MAX = 'max',
  OTHER = 'other',
}

export type VisualisationFieldNameInterface = FieldNameInterface;

export interface CustomRequestInterface {
  customRequest: string | null;
}

export interface VisualisationOperationTypeInterface extends CustomRequestInterface {
  operationType: VisualisationOperationTypesType;
}

export type VisualisationIdInterface = IdInterface;

export interface IsShowValueInterface {
  isShowValue: boolean;
}

export interface ActiveObjectFilters extends IsActiveInterface {
  id: string;
  selectedValues: string[] | DateFilterDataInterface | null;
  elementId: string;
}

export interface ActivateObjectInterface extends IsActiveInterface {
  filters: ActiveObjectFilters[] | null;
  isActive: boolean;
}

type EventObjectType = 'page' | 'visualisation';

interface VisualisationEventObjectInterface extends VisualisationIdInterface {
  type: EventObjectType;
}

interface GoToEventInterface<To> extends IsActiveInterface {
  to: To | null;
}

export type OpenWindowType = 'self' | 'blank';

type GoToHrefEventInterface = GoToEventInterface<string> & {
  openWindowType: OpenWindowType;
};

interface ActivateToEventInterface extends IsActiveInterface {
  to: VisualisationEventObjectInterface[] | null;
}

export interface DefaultEventsSettingsInterface {
  goTo: GoToEventInterface<VisualisationEventObjectInterface>;
  goToHref: GoToHrefEventInterface;
  activateTo: ActivateToEventInterface;
  activateObject: ActivateObjectInterface;
  isReactingToFilter: boolean;
  filterSettings: FilterSettingsInterface;
  variablesSettings: VariablesSettingsInterface;
}

export interface VariablesSettingsInterface extends IsActiveInterface {
  variables: VariableInterface[];
  control: Record<ControlVariableTypes, ControlVariableInterface>;
}

export type ControlVariableTypes = 'view';

export interface ControlVariableInterface extends IsActiveInterface {
  variableId: string;
  variableName: string;
}

export interface VariableInterface {
  variableId: string;
  status: VariableExpandedStatusType | string;
}

export interface FilterSettingsInterface {
  isFiltering: boolean;
  applyToAllPages: boolean;
  moreThanOne: boolean;
  isInfluenceItself: boolean;
  filterInfluences: MapRecordType<boolean> | null;
  responseToExternalDrillDown: boolean;
}

export interface CodeEditorDataInterface {
  indicatorSqlString?: string;
  incisionSqlString?: string;
  filterAndGroupSqlString?: string;
}

export type GroupByType = 'day' | 'dayOfWeek' | 'month' | 'quarter' | 'year';
export type GroupByMaskOfYearType = '%Y' | '%y';
export type GroupByMaskOfOtherType = 'long' | 'short';
export type GroupByMaskOfDayType = '%d.%m.%Y' | '%m/%d/%Y' | '%Y.%m.%d';

export type GroupByTypeGeneric<Type extends GroupByType = 'day'> = Type extends 'day'
  ? 'day'
  : Type extends 'year'
  ? 'year'
  : Type extends 'dayOfWeek' | 'month' | 'quarter'
  ? 'dayOfWeek' | 'month' | 'quarter'
  : never;

export type GroupByMaskGeneric<Type extends GroupByType = 'day'> = Type extends 'day'
  ? GroupByMaskOfDayType
  : Type extends 'year'
  ? GroupByMaskOfYearType
  : Type extends 'dayOfWeek' | 'month' | 'quarter'
  ? GroupByMaskOfOtherType
  : never;

export interface GroupByDateInterface<Type extends GroupByType = 'day'> extends IsShowInterface {
  groupByType: GroupByTypeGeneric<Type>;
  groupByMask: GroupByMaskGeneric<Type>;
}

export interface GroupByDateSettingsInterface {
  groupByDateSettings: GroupByDateInterface<GroupByType>;
}

export interface ActiveIncisionIdInterface {
  activeIncisionId: string | null;
}

export interface IsVisibleInterface {
  isVisible: boolean;
}

export interface IsBlockInterface {
  isBlock: boolean;
}

export interface IncisionSelectorPositionInterface extends IsVisibleInterface {
  incisionSelectorPosition: PositionSettingType;
}

export interface VisualisationOptionsInterface<
  VisualisationDataSettings extends DefaultDataSettingsType,
  VisualisationEventsSettings extends DefaultEventsSettingsInterface,
  VisualisationViewSettings extends DefaultViewSettingsInterface,
  VisualisationBackgroundImagesSettings extends DefaultBackgroundImagesSettingsType,
  VisualisationSqlDataSettings extends DefaultSqlDataInterface,
  VisualisationType extends VisualisationTypeType,
> extends VisualisationIdInterface,
    PageIdInterface,
    PositionConfigSettingsInterface,
    IsVisibleInterface,
    IsBlockInterface {
  dataSettings: VisualisationDataSettings;
  viewSettings: VisualisationViewSettings;
  backgroundImagesSettings: VisualisationBackgroundImagesSettings;
  sqlData: VisualisationSqlDataSettings;
  visualisationType: VisualisationType;
  events: VisualisationEventsSettings;
}

export type DefaultVisualisationOptionsType = VisualisationOptionsInterface<
  DefaultDataSettingsType,
  DefaultEventsSettingsInterface,
  DefaultViewSettingsInterface,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  VisualisationTypeType
>;

export type VisualisationsMapInterface = MapRecordType<DefaultVisualisationOptionsType>;

export type VisualisationIdsByPageInterface = MapRecordType<Set<string>>;

export interface AllPreviewTemplateVisualisationsListInterface extends IdInterface, NameInterface {
  type: VisualisationTypeType;
  icon: string | null;
}

export interface AllPreviewTemplateVisualisationsInterface extends LoadingInterface {
  allPreviewTemplateVisualisationsList: AllPreviewTemplateVisualisationsListInterface[];
}

/* Store SLice Type */

export interface VisualisationStateInterface {
  visualisationData: VisualisationDataMapInterface;
  visualisations: VisualisationsMapInterface;
  serverStateOfVisualisations: VisualisationsMapInterface | null;
  visualisationsByPages: VisualisationIdsByPageInterface;
  visualisationsLoading: boolean;
  visualisationsLoadingList: MapRecordType<boolean>;
  visualisationsErrorsList: MapRecordType<string | null>;
  alreadyLoadedContent: {
    visualisations: Set<string>;
  };
  allPreviewTemplateVisualisations: AllPreviewTemplateVisualisationsInterface;
}

/* Action Types */

export enum VisualisationActionsTypes {
  ADD_VISUALISATION = 'ADD_VISUALISATION',
  ADD_TEMPLATE_VISUALISATION = 'ADD_TEMPLATE_VISUALISATION',
  ADD_VISUALISATION_BY_DATA = 'ADD_VISUALISATION_BY_DATA',
  REMOVE_VISUALISATION_BY_ID = 'REMOVE_VISUALISATION_BY_ID',
  UPDATE_VIEW_SETTINGS_BY_ID = 'UPDATE_VIEW_SETTINGS_BY_ID',
  UPDATE_VIEW_SETTINGS = 'UPDATE_VIEW_SETTINGS',
  UPDATE_STYLE_VIEW_SETTINGS_BY_ID = 'UPDATE_STYLE_VIEW_SETTINGS_BY_ID',
  UPDATE_DATA_SETTINGS = 'UPDATE_DATA_SETTINGS',
  UPDATE_BACKGROUND_DATA_SETTINGS = 'UPDATE_BACKGROUND_DATA_SETTINGS',
  UPDATE_SQL_SETTINGS = 'UPDATE_SQL_SETTINGS',
  UPDATE_SQL_SETTINGS_BY_ID = 'UPDATE_SQL_SETTINGS_BY_ID',
  UPDATE_POSITION_CONFIG_BY_ID = 'UPDATE_POSITION_CONFIG_BY_ID',
  UPDATE_POSITION_CONFIG = 'UPDATE_POSITION_CONFIG',
  UPDATE_EVENT_SETTINGS = 'UPDATE_EVENT_SETTINGS',
  UPDATE_VISUALISATION_VALUES = 'UPDATE_VISUALISATION_VALUES',
  REMOVE_VISUALISATION_VALUES = 'REMOVE_VISUALISATION_VALUES',
  REMOVE_FROM_VISUALISATION_LOADING_LIST = 'REMOVE_FROM_VISUALISATION_LOADING_LIST',
  LOAD_VISUALISATION_VALUES = 'LOAD_VISUALISATION_VALUES',
  GET_VISUALISATION_VALUES = 'GET_VISUALISATION_VALUES',
  LOAD_VISUALISATIONS = 'LOAD_VISUALISATIONS',
  LOAD_VISUALISATIONS_BY_PAGE_ID = 'LOAD_VISUALISATIONS_BY_PAGE_ID',
  LOAD_VISUALISATIONS_FROM_SNAPSHOT = 'LOAD_VISUALISATIONS_FROM_SNAPSHOT',
  CLEAR_VISUALISATIONS_STORE = 'CLEAR_VISUALISATIONS_STORE',
  EXPORT_XLSX_BY_ID = 'EXPORT_XLSX_BY_ID',
  UPDATE_DATA_SETTINGS_SQL_DATA = 'UPDATE_DATA_SETTINGS_SQL_DATA',
  UPDATE_INDICATOR_STACK_SUM = 'UPDATE_INDICATOR_STACK_SUM',
  LOAD_ALL_PREVIEW_TEMPLATE_VISUALISATIONS = 'LOAD_ALL_PREVIEW_TEMPLATE_VISUALISATIONS',
  LOAD_TEMPLATE_VISUALISATION = 'LOAD_TEMPLATE_VISUALISATION',
  UPDATE_POSITION_CONFIG_SEVERAL_WIDGETS = 'UPDATE_POSITION_CONFIG_SEVERAL_WIDGETS',
}

/* Creation Functions Types */

export type DataInitialVisualisationsType<
  VisualisationDataSettings extends DefaultDataSettingsType,
  VisualisationEventsSettings extends DefaultEventsSettingsInterface,
  VisualisationViewSettings extends DefaultViewSettingsInterface,
  VisualisationBackgroundImagesSettings extends DefaultBackgroundImagesSettingsType,
  VisualisationSqlDataSettings extends DefaultSqlDataInterface,
  VisualisationType extends VisualisationTypeType,
> = Pick<
  VisualisationOptionsInterface<
    VisualisationDataSettings,
    VisualisationEventsSettings,
    VisualisationViewSettings,
    VisualisationBackgroundImagesSettings,
    VisualisationSqlDataSettings,
    VisualisationType
  >,
  'pageId' | 'id'
> &
  NameInterface;

export type InitialVisualisationsFnType<
  VisualisationDataSettings extends DefaultDataSettingsType,
  VisualisationEventsSettings extends DefaultEventsSettingsInterface,
  VisualisationViewSettings extends DefaultViewSettingsInterface,
  VisualisationBackgroundImagesSettings extends DefaultBackgroundImagesSettingsType,
  VisualisationSqlDataSettings extends DefaultSqlDataInterface,
  VisualisationType extends VisualisationTypeType,
> = (
  data: DataInitialVisualisationsType<
    VisualisationDataSettings,
    VisualisationEventsSettings,
    VisualisationViewSettings,
    VisualisationBackgroundImagesSettings,
    VisualisationSqlDataSettings,
    VisualisationType
  >,
) => VisualisationOptionsInterface<
  VisualisationDataSettings,
  VisualisationEventsSettings,
  VisualisationViewSettings,
  VisualisationBackgroundImagesSettings,
  VisualisationSqlDataSettings,
  VisualisationType
>;

export type InitialVisualisationsFnsType = Record<
  VisualisationTypeType,
  InitialVisualisationsFnType<
    DefaultDataSettingsType,
    DefaultEventsSettingsInterface,
    DefaultViewSettingsInterface,
    DefaultBackgroundImagesSettingsType,
    DefaultSqlDataInterface,
    VisualisationTypeType
  >
>;

export interface InitialVisualisationProps {
  visualisationType: VisualisationTypeType;
  data: DataInitialVisualisationsType<
    DefaultDataSettingsType,
    DefaultEventsSettingsInterface,
    DefaultViewSettingsInterface,
    DefaultBackgroundImagesSettingsType,
    DefaultSqlDataInterface,
    VisualisationTypeType
  >;
}

/* Payload Interfaces */

export type AddNewVisualisationPayload = InitialVisualisationProps & Partial<PositionConfigSettingsInterface>;

export type AddNewVisualisationByDataPayload = DefaultVisualisationOptionsType;

export interface VisualisationValuesPayload extends VisualisationIdInterface {
  data: VisualisationValuesInterface;
}

export interface UpdatePositionConfigPayload extends VisualisationIdInterface {
  positionConfig: Partial<BoardPositionConfigInterface>;
}

export interface UpdateVisibleSettingsPayload extends VisualisationIdInterface {
  isVisible: boolean;
}

export interface UpdateBlockSettingsPayload extends VisualisationIdInterface {
  isBlock: boolean;
}

export interface UpdateSqlDataPayload extends VisualisationIdInterface {
  data: Partial<DefaultSqlDataInterface>;
}

export interface UpdateVisualisationsLoadingList extends VisualisationIdInterface {
  id: string;
}

export interface UpdateVisualisationsErrorList extends VisualisationIdInterface {
  id: string;
  text?: string | null;
}

export interface UpdateEventsDataPayload extends VisualisationIdInterface {
  events: Partial<DefaultEventsSettingsInterface>;
}

export interface UpdateViewSettingsPayload extends VisualisationIdInterface {
  viewSettings: Partial<DefaultViewSettingsInterface>;
}

export interface UpdateBackgroundSettingsPayload extends VisualisationIdInterface {
  backgroundSettings: Partial<DefaultBackgroundImagesSettingsInterface>;
}

export interface UpdateDataSettingsPayload<
  VisualisationDataSettings extends DefaultDataSettingsInterface = DefaultDataSettingsInterface,
> extends VisualisationIdInterface {
  dataSettings: Partial<VisualisationDataSettings>;
}

export interface LoadVisualisationValuesPayload extends StrictKeys<ModelIdInterface> {
  visualisationId: string;
  projectId: string;
  sqlRequest: string;
  rls?: RLSInterface;
}

export interface IncisionSelectItemInterface extends SelectItemInterface, IdInterface, CustomRequestInterface {}

export interface IndicatorSelectItemInterface extends SelectItemInterface, IdInterface, VisualisationOperationTypeInterface {}

export interface SqlDependencyInterface
  extends IsRealDataInterface,
    Partial<ActiveIncisionIdInterface>,
    ServiceSelectValuesInterface,
    VariablesSelectValuesInterface,
    ImagesSelectValuesInterface,
    IdInterface,
    DefaultSqlDataInterface {
  incisions: IncisionSelectItemInterface[];
  indicators: IndicatorSelectItemInterface[];
  limit?: SqlLimitInterface;
}

export interface SqlDependencyTableInterface
  extends IsRealDataInterface,
    Partial<ActiveIncisionIdInterface>,
    ServiceSelectValuesInterface,
    VariablesSelectValuesInterface,
    ImagesSelectValuesInterface,
    IdInterface,
    DefaultSqlDataInterface {
  incisions: IncisionSelectItemInterface[];
  incisionsInHeader: IncisionSelectItemInterface[];
  indicators: IndicatorSelectItemInterface[];
  limit?: SqlLimitInterface;
}

export type LoadVisualisationsOutput = Pick<VisualisationStateInterface, 'visualisationsByPages' | 'visualisations'>;

/* Visualisation Interfaces */

/* Tree */
export interface SignaturesInterface extends IsShowInterface {
  position: PositionSettingType;
  location: PositionSettingType;
}

export interface TreeIncisionInterface extends DefaultIncisionInterface {
  colors: PaletteValuesByThemeType | null;
  propertiesIncisionName: DefaultPropertiesInterface;
  propertiesIncisionValue: DefaultPropertiesInterface;
  propertiesIncisionNameValue: DefaultPropertiesInterface;
}

export interface TreeIndicatorSettingsInterface extends DefaultIndicatorSettingsInterface, IsShowValueInterface {}

export interface TreeIndicatorInterface extends DefaultIndicatorInterface {
  settings: TreeIndicatorSettingsInterface;
}

export interface TreeDataSettings extends DefaultDataSettingsInterface {
  incisions: TreeIncisionInterface[];
  indicators: TreeIndicatorInterface[];
}

export interface TreeViewSettings extends StrictDefaultViewSettingsInterface, ShowTipsInterface {
  showLegend: boolean;
  showNameIncision: boolean;
  signatures: SignaturesInterface;
}

export type TreeVisualisationType = VisualisationOptionsInterface<
  TreeDataSettings,
  DefaultEventsSettingsInterface,
  TreeViewSettings,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  'tree'
>;

/* Text */
export type TextImageType = 'link' | 'fromDatabase';

export interface ColorSettingsStateInterface extends IsShowInterface {
  color: ColorValuesByThemeType | null;
}

export interface TextPositionInterface {
  positionHorizontal: PositionSettingType;
  positionVertically: PositionSettingType;
}

export interface BackgroundSettingsInterface extends IsShowInterface {
  colorSettings: {
    background: ColorSettingsStateInterface;
    hover: ColorSettingsStateInterface;
    active: ColorSettingsStateInterface;
    backgroundColorBy: ColorByInterface;
  };
}

export interface TextCarouselAutoPlayInterface extends IsActiveInterface {
  value: number;
}

export interface TextPropertiesSettingsInterface extends DefaultPropertiesInterface {
  text: TextSettingInterface;
}

export interface TextVariablesSettingsInterface {
  formatting: FormattingInterface;
  textPropertiesSettings: TextPropertiesSettingsInterface;
}

export interface FrameVariablesSettingsInterface {
  formatting: FormattingInterface;
  textPropertiesSettings: TextPropertiesSettingsInterface;
}

export interface GeometricShapesVariablesSettingsInterface {
  formatting: FormattingInterface;
  textPropertiesSettings: TextPropertiesSettingsInterface;
}

export interface TextVariablesInterface
  extends CustomRequestInterface,
    VisualisationIdInterface,
    Pick<VisualisationFieldNameInterface, 'name'> {
  settings: TextVariablesSettingsInterface;
  emptyValues: IndicatorEmptyValuesInterface;
}

export interface FrameVariablesInterface
  extends CustomRequestInterface,
    VisualisationIdInterface,
    Pick<VisualisationFieldNameInterface, 'name'> {
  settings: FrameVariablesSettingsInterface;
  emptyValues: IndicatorEmptyValuesInterface;
}

export interface GeometricShapesVariablesInterface
  extends CustomRequestInterface,
    VisualisationIdInterface,
    Pick<VisualisationFieldNameInterface, 'name'> {
  settings: GeometricShapesVariablesSettingsInterface;
  emptyValues: IndicatorEmptyValuesInterface;
}

export interface ImagesInterfaceTextImagesInterface
  extends CustomRequestInterface,
    VisualisationIdInterface,
    VisualisationFieldNameInterface,
    FictionalDataInterface,
    VisualisationFieldNameInterface {}

export interface TextPropertiesInterface extends DefaultPropertiesInterface {
  lineHeight: number;
  color: ColorValuesByThemeType | null;
  opacity: number;
  padding: ConnectedCellPaddingSettings;
  fontColorBy: ColorByInterface;
}

export interface ScrollSettingsInterface {
  showHorizontalScroll: boolean;
  showVerticalScroll: boolean;
}

export interface TextViewSettings extends DefaultViewSettingsInterface, ScrollSettingsInterface {
  text: TextSettingColorInterface;
  textProperties: DefaultPropertiesInterface;
}

export interface FrameViewSettings extends DefaultViewSettingsInterface, ScrollSettingsInterface {
  code: TextSettingColorInterface;
  position: TextPositionInterface;
  textProperties: DefaultPropertiesInterface;
  headerSettings: TableHeaderSettingsInterface;
}

export interface BackgroundImagesSettingsInterface {
  imagesSettings: {
    backgroundImagesBy: ImageByInterface;
  };
}

export interface BackgroundImageSelectorProportionsInterface {
  proportionsImageType: ProportionsImageSettingType;
}

export interface TextBackgroundSettings
  extends DefaultBackgroundImagesSettingsInterface,
    BackgroundImageSelectorProportionsInterface {
  backgroundImages: BackgroundImagesSettingsInterface;
  carouselAutoPlay: TextCarouselAutoPlayInterface;
}

export interface TextDataSettings extends DefaultDataSettingsInterface {
  incisions: DefaultIncisionInterface[];
  variables: TextVariablesInterface[];
}

export interface FrameDataSettings extends DefaultDataSettingsInterface {
  incisions: DefaultIncisionInterface[];
  variables: TextVariablesInterface[];
}

export interface GeometricShapesDataSettings extends DefaultDataSettingsInterface {
  incisions: DefaultIncisionInterface[];
  variables: TextVariablesInterface[];
}

export type TextVisualisationType = VisualisationOptionsInterface<
  TextDataSettings,
  DefaultEventsSettingsInterface,
  TextViewSettings,
  TextBackgroundSettings,
  DefaultSqlDataInterface,
  'text'
>;

/* Frame */

export type FrameVisualisationType = VisualisationOptionsInterface<
  FrameDataSettings,
  DefaultEventsSettingsInterface,
  FrameViewSettings,
  TextBackgroundSettings,
  DefaultSqlDataInterface,
  'frame'
>;

/* Pie */

export type PieIndicatorValueFormatType = 'absolute' | 'percent';
export type PieIndicatorLabelFormatType = 'value' | 'name';

interface PieIncisionSettingsInterface extends DefaultIncisionSettingsInterface, GroupByDateSettingsInterface {}

export interface PieIncisionInterface extends DefaultIncisionInterface {
  colors: PaletteValuesByThemeType | null;
  settings: PieIncisionSettingsInterface;
  colorBySettings: ColorSettingsIncision;
}

interface PieIndicatorsSettingsInterface extends DefaultIndicatorSettingsInterface {
  properties: DefaultPropertiesInterface;
  valueFormat: PieIndicatorValueFormatType;
}

export interface CenterYCoordinatesInterface extends IsActiveInterface {
  centerXCoordinates: DesignOptionInterface;
  centerYCoordinates: DesignOptionInterface;
}

export interface PieDesignInterface {
  pieAnimationElement: boolean;
  borderRadius: number;
  borderWidth: number;
  outerRadius: DesignOptionInterface;
  innerRadius: DesignOptionInterface;
  centerCoordinates: CenterYCoordinatesInterface;
  arcStartAngle: number;
  arcEndAngle: number;
  colorBySettings: ColorSettingsIncision;
  properties: DefaultPropertiesInterface;
  labelFormat: PieIndicatorLabelFormatType[];
}

export interface PieIndicatorInterface extends DefaultIndicatorInterface {
  settings: PieIndicatorsSettingsInterface;
}

export type PieRoseTypeTypes = 'pie' | 'area';

export interface PieDataSettings extends DefaultDataSettingsInterface, ActiveIncisionIdInterface {
  incisions: PieIncisionInterface[];
  indicators: PieIndicatorInterface[];
  roseType: PieRoseTypeTypes;
}

export interface PieViewSettings
  extends StrictDefaultViewSettingsInterface,
    ShowTipsInterface,
    NameInterface,
    IncisionSelectorPositionInterface {
  legendSettings: LegendSettingsInterface;
  styleSettings: PieDesignInterface;
}

export type PieVisualisationType = VisualisationOptionsInterface<
  PieDataSettings,
  DefaultEventsSettingsInterface,
  PieViewSettings,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  'pie'
>;

/* Bar */

export type BarType = 'group' | 'pile' | 'nominated';

export interface BarTypeInterface {
  type: BarType;
  activePile: boolean;
}

export interface BarDataSettings extends DefaultDataSettingsInterface {
  showSum: ShowSumInterface;
}

export interface BarIncisionInterface extends DefaultIncisionInterface {
  colors: PaletteValuesByThemeType | null;
  colorBySettingsValueAndElement: ColorBySettingsValueAndElementInterface;
  emptyValues: IndicatorEmptyValuesInterface;
}

export interface BarViewSettings
  extends StrictDefaultViewSettingsInterface,
    ShowTipsInterface,
    NameInterface,
    IncisionSelectorPositionInterface {
  legendSettings: LegendSettingsInterface;
  horizontalZoom: ZoomSettingsInterface;
  verticalZoom: ZoomSettingsInterface;
  dataZoomHorizontalStartAndEnd: StartAndEndInterface;
  dataZoomVerticalStartAndEnd: StartAndEndInterface;
  visualisationPaddings: PaddingVisualisationInterface;
}

export interface BarIndicatorSettingsInterface extends DefaultIndicatorSettingsInterface {
  showValue: ShowValueSettingsInterface;
}

export interface BarIndicatorInterface extends DefaultIndicatorInterface {
  settings: BarIndicatorSettingsInterface;
  color: ColorValuesByThemeType | null;
}

/* Bubble */

export type BubbleSizeMode = 'default' | 'byIndicator';

export type BubbleSizeModeMinAndMax = Omit<MinAndMaxInterface, 'isShow'>;

export interface BubbleSizeModeSettingsInterface {
  type: BubbleSizeMode;
  parameters: {
    diameter: number;
    minAndMax: BubbleSizeModeMinAndMax;
  };
}

export interface BubbleIndicatorSettingsInterface extends DefaultIndicatorSettingsInterface {
  minAndMax: MinAndMaxInterface;
  sizeSettings: BubbleSizeModeSettingsInterface;
}

export interface BubbleIndicatorInterface extends DefaultIndicatorInterface {
  settings: BubbleIndicatorSettingsInterface;
}

export interface BubbleSettingsInterface {
  rangeIndicator: RangeIndicatorIdInterface;
  gradientValueColorSettings: GradientColorSettings;
}

export interface BubbleIncisionSettingsInterface extends DefaultIncisionSettingsInterface {
  showValue: ShowValueSettingsInterface;
  bubbleSettings: BubbleSettingsInterface;
}

export interface BubbleIncisionInterface extends DefaultIncisionInterface {
  colors: PaletteValuesByThemeType | null;
  settings: BubbleIncisionSettingsInterface;
}

export interface BubbleDataSettings extends Omit<BarDataSettings, 'minAndMax'>, ActiveIncisionIdInterface {
  rangeIndicator: RangeIndicatorIdInterface;
  type: BarType;
  incisions: BubbleIncisionInterface[];
  indicators: BubbleIndicatorInterface[];
}

export interface RangeIndicatorIdInterface extends IsShowInterface {
  rangeIndicatorId: string;
}

export interface BubbleElementDesignSettingsInterface {
  rangeIndicator: RangeIndicatorIdInterface;
  gradientElementColorSettings: GradientColorSettings;
  properties: DefaultPropertiesInterface;
  colorValueColorSettings: GradientColorSettings;
}

export interface BubbleViewSettings extends BarViewSettings {
  dataVisualMapSelectedMinAndMax: StartAndEndInterface;
  axisXIncisionSettings: AxisSettingsInterface;
  axisYIncisionSettings: AxisSettingsInterface;
  visualMapSettings: VisualBubbleMapSettingsInterface;
  elementDesign: BubbleElementDesignSettingsInterface;
}

export type BubbleVisualisationType = VisualisationOptionsInterface<
  BubbleDataSettings,
  DefaultEventsSettingsInterface,
  BubbleViewSettings,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  'bubble'
>;

export type VisualBubbleMapSettingsInterface = ExtendedSettingsInterface;

/* Heatmap */

export type HeatmapIndicatorInterface = BarIndicatorInterface;

export type HeatmapIncisionInterface = BarIncisionInterface;

export interface HeatmapDataSettings extends BarDataSettings, GradientBackgroundByValueSettingsInterface {
  type: BarType;
  incisions: HeatmapIncisionInterface[];
  verticalIncisions: HeatmapIncisionInterface[];
  indicators: HeatmapIndicatorInterface[];
  activeVerticalIncisionId: string | null;
  activeHorizontalIncisionId: string | null;
}

export interface HeatmapElementDesignSettingsInterface {
  gradientElementColorSettings: GradientColorSettings;
  properties: DefaultPropertiesInterface;
  colorValueColorSettings: GradientColorSettings;
}

export interface HeatmapViewSettings extends BarViewSettings {
  dataVisualMapSelectedMinAndMax: StartAndEndInterface;
  axisXIncisionSettings: AxisSettingsInterface;
  axisYIncisionSettings: AxisSettingsInterface;
  visualMapSettings: VisualMapSettingsInterface;
  elementDesign: HeatmapElementDesignSettingsInterface;
}

export type HeatmapVisualisationType = VisualisationOptionsInterface<
  HeatmapDataSettings,
  DefaultEventsSettingsInterface,
  HeatmapViewSettings,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  'heatmap'
>;

export type VisualMapSettingsInterface = ExtendedSettingsInterface;

/* Waterfall */

export interface WaterfallIndicatorTypeInterface {
  type: 'plan' | 'fact' | 'negative' | 'positive' | 'phantom' | 'default';
}

export interface WaterfallIndicatorInterface extends DefaultIndicatorInterface, WaterfallIndicatorTypeInterface {
  settings: WaterfallIndicatorSettingsInterface;
  color: ColorValuesByThemeType | null;
}

export interface WaterfallElementDesignSettingsInterface {
  properties: DefaultPropertiesInterface;
}

export interface WaterfallViewSettings extends BarViewSettings {
  axisIncisionSettings: AxisSettingsInterface;
  axisIndicatorSettings: AxisSettingsInterface<'indicator'>;
  elementDesign: WaterfallElementDesignSettingsInterface;
}

export interface WaterfallDataSettings extends BarDataSettings, ActiveIncisionIdInterface {
  type: BarType;
  phantomIndicators: WaterfallIndicatorInterface[];
  incisions: WaterfallIncisionInterface[];
  indicators: WaterfallIndicatorInterface[];
  positiveAndNegativeIndicators: WaterfallIndicatorInterface[];
}

export interface WaterfallIndicatorInterface extends WaterfallIndicatorTypeInterface, BarIndicatorInterface {}

export type WaterfallIncisionInterface = BarIncisionInterface;

export type WaterfallVisualisationType = VisualisationOptionsInterface<
  WaterfallDataSettings,
  DefaultEventsSettingsInterface,
  WaterfallViewSettings,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  'waterfall'
>;

export type WaterfallIndicatorSettingsInterface = BarIndicatorSettingsInterface;

/* LineAndBar */

export interface ShowSumInterface extends IsShowInterface, OrientationInterface {}

export interface ColorBySettingsValueAndElementInterface {
  colorBySettingsValue: ColorSettingsIncision;
  colorBySettingsElement: ColorSettingsIncision;
}

export type LineAndBarIncisionInterface = BarIncisionInterface;

export type ShowValuePositionGeneric<IndicatorType extends LineAndBarIndicatorType = 'bar'> = IndicatorType extends 'bar'
  ? BarValuePositionType
  : IndicatorType extends 'line'
  ? TopAndBottomType
  : never;

export interface ShowValueSettingsInterface<IndicatorType extends LineAndBarIndicatorType = 'bar'>
  extends IsShowInterface,
    OrientationInterface {
  position: ShowValuePositionGeneric<IndicatorType>;
  properties: DefaultPropertiesInterface;
  colorBySettings: ColorSettingsIndicator;
}

export interface ElementSettingsInterface {
  type: LineAndBarIndicatorType;
  parameters: {
    lineWidth: number;
    dotWidth: number;
    areaOpacity: number;
    isDotted: boolean;
    lineType: LineType;
  };
  parametersBar: {
    barWidth: DesignOptionInterface;
    barMaxWidth: DesignOptionInterface;
    barMinWidth: DesignOptionInterface;
    stackNumber: number;
    stackOffset: string;
  };

  colorBySettings: ColorSettingsIndicator;
}

export type LineAndBarIndicatorType = 'bar' | 'line';

export type LineType = 'broken' | 'smooth' | 'stepByStep';

export type BarValuePositionType = PositionSettingType | 'outside';

export interface LineAndBarIndicatorSettingsInterface extends BarIndicatorSettingsInterface {
  additionalIndicators: boolean;
  elementSettings: ElementSettingsInterface;
}

export type LineAndBarTypeIndicator = 'indicator' | 'indicatorsStackSum';

export interface LineAndBarIndicatorInterface extends DefaultIndicatorInterface {
  settings: LineAndBarIndicatorSettingsInterface;
  color: ColorValuesByThemeType | null;
  type: 'indicator' | 'indicatorsStackSum';
}

export interface MinAndMaxInterface extends IsShowInterface {
  min: number;
  max: number;
}

export interface LineAndBarDataSettings extends BarDataSettings, ActiveIncisionIdInterface {
  rotateTo90: boolean;
  type: BarType;
  barType: BarTypeInterface;
  indicators: LineAndBarIndicatorInterface[];
  indicatorsStackSum: LineAndBarIndicatorInterface[];
  incisions: LineAndBarIncisionInterface[];
}

export type LabelsOrientationType = OrientationType | 'incline';
export type LabelOrderValuesType = 'downUp' | 'topDown';

export type AxisType = 'incision' | 'indicator';

export type CenterOrEdgePositionType = 'center' | 'edge';

export enum BorderSpecificValueEnum {
  MANUALLY = 'MANUALLY',
  AUTOMATICALLY = 'AUTOMATICALLY',
}

export enum ElementSpecificValueEnum {
  PERCENT = 'PERCENT',
  ABSOLUTE = 'ABSOLUTE',
}

export enum SortOrderEnum {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

export interface GetSpecificGradientInterpolatorParams {
  actualColors: string[];
  rawData: SpecificColorByItem[];
  minValue?: number;
  maxValue?: number;
  valueType?: ElementSpecificValueEnum;
  borderType?: BorderSpecificValueEnum;
}

export interface TickSettingInterface extends IsShowInterface {
  position: CenterOrEdgePositionType;
}

export type AxisSettingsNameType<Type extends AxisType = 'incision'> = (Type extends 'incision'
  ? IsShowInterface
  : TextSettingInterface) & { type: AutoEndManualType; position: PositionValueType };

export type AxisSettingsInterface<Type extends AxisType = 'incision'> = IsShowInterface & {
  showAxis: boolean;
  position: Type extends 'incision' ? AbsolutePositionType : AbsolutePositionType;
  name: AxisSettingsNameType<Type>;
  label: LabelSettingInterface;
  stepSize: AutoOrManualInterface;
  colorOfAxisLinesSettings: ColorDefaultSettingsInterface;
  showGrid: boolean;
  minAndMax: MinAndMaxInterface;
} & (Type extends 'incision'
    ? {
        labelOrientation: LabelsOrientationType;
        labelOrderValues: LabelOrderValuesType;
        labelSize: AutoOrManualInterface;
        tickLabel: TickSettingInterface;
      }
    : // eslint-disable-next-line @typescript-eslint/ban-types
      {});

export interface LabelSettingInterface extends IsActiveInterface {
  value: number;
  properties: DefaultPropertiesInterface;
}

export interface ExtendedSettingsInterface extends IsShowInterface {
  type: 'manual';
  location: LocationValueType;
  position: PositionValueType;
  width: number;
  properties: DefaultPropertiesInterface;
}

export type LegendSettingsInterface = ExtendedSettingsInterface;

export type LocationValueType = {
  type: AbsolutePositionType;
  value: number;
};

export type PositionValueType = {
  type: PositionSettingType;
  value: number;
};

export interface ZoomSettingsInterface extends IsShowInterface {
  padding: AutoOrManualInterface;
}

export interface PaddingVisualisationInterface extends IsShowInterface {
  paddingsVisualisation: PaddingsVisualisationInterface;
}

export type ColorStrategyType = 'byIndicator' | 'byIncision';

export interface ElementDesignInterface {
  colorStrategyType: ColorStrategyType;
  colorSettingsIndicator: ColorSettingsIndicator;
  colorSettingsIncision: ColorSettingsIncision;
  properties: DefaultPropertiesInterface;
  propertiesIndicatorColorSettings: ColorSettingsIndicator;
  propertiesIncisionColorSettings: ColorSettingsIncision;
}

export interface LineAndBarViewSettings extends BarViewSettings {
  axisAdditionalIndicatorSettings: AxisSettingsInterface<'indicator'>;
  axisIncisionSettings: AxisSettingsInterface;
  axisIndicatorSettings: AxisSettingsInterface<'indicator'>;
  elementDesign: ElementDesignInterface;
}

export type LineAndBarVisualisationType = VisualisationOptionsInterface<
  LineAndBarDataSettings,
  DefaultEventsSettingsInterface,
  LineAndBarViewSettings,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  'lineAndBar'
>;

/* Table */

export interface ColumnWidthSettings extends IsActiveInterface {
  width: number;
}

export interface ColumnWidthSettingsInterface {
  columnWidthSettings: ColumnWidthSettings;
}

export interface SortSettings extends IsActiveInterface {
  type: 'desc' | 'asc';
}

export interface SortSettingsInterface {
  sortSettings: SortSettings;
}

export type SourceTypes = 'database' | 'byCondition' | 'manual';

export enum SourceEnum {
  DATABASE = 'database',
  BYCONDITION = 'byCondition',
  MANUAL = 'manual',
}

export interface TableIncisionSettingsInterface
  extends DefaultIncisionSettingsInterface,
    ColumnWidthSettingsInterface,
    SortSettingsInterface {
  properties: DefaultPropertiesInterface;
  indentation: IndentationInterface;
  hyperLink: MakeHyperLinksInterface;
  imagesSettings: ImagesSettingsInterface;
  hasIndentation: boolean;
  isSort: boolean;
}

export interface TableIncisionInterface extends DefaultIncisionInterface {
  settings: TableIncisionSettingsInterface;
  color: ColorValuesByThemeType | null;
}

export interface IsAutoAggregationInterface {
  isAutoAggregation: boolean;
  formatting: FormattingInterface;
}

export interface TotalSettings extends IsShowInterface, VisualisationOperationTypeInterface, IsAutoAggregationInterface {}

/* TODO: Make dynamic markers as SQL query  */
export type DynamicMarkerSymbolType = 'rect' | 'rhombus' | 'circle' | 'star' | 'cross' | 'arrowUp' | 'arrowDown';
export type DynamicMarkerModeType = 'manual' | 'sql';

export enum DynamicMarkerModeEnum {
  MANUAL = 'manual',
  SQL = 'sql',
}

export interface MarkerSettingsInterface {
  color: ColorValuesByThemeType | null;
  marker: MarkersType;
}

export type MarkersType =
  | 'upMarker'
  | 'DownMarker'
  | 'SquareMarker'
  | 'CircleMarker'
  | 'SimSMarker'
  | 'PlusMarker'
  | 'MinusMarker'
  | 'CheckMarker'
  | 'CrossMarker';

export enum MarkersEnum {
  UPMARKER = 'upMarker',
  DOWNMARKER = 'DownMarker',
  SQUAREMARKER = 'SquareMarker',
  CIRCLEMARKER = 'CircleMarker',
  SIMSMARKER = 'SimSMarker',
  PLUSMARKER = 'PlusMarker',
  MINUSMARKER = 'MinusMarker',
  CHECKMARKER = 'CheckMarker',
  CROSSMARKER = 'CrossMarker',
}

export type ConditionsMarkerType = 'more' | 'moreOrEqual' | 'equal' | 'lessOrEqual' | 'less';

export enum ConditionsMarkerEnum {
  MORE = 'more',
  MOREOREQUAL = 'moreOrEqual',
  EQUAL = 'equal',
  LESSOREQUAL = 'lessOrEqual',
  LESS = 'less',
}

export interface CustomMarkersInterface extends MarkerSettingsInterface {
  values: [number, number];
  conditions: [ConditionsMarkerType, ConditionsMarkerType];
  id: string;
}

export interface DynamicMarkerByConditionInterface {
  markers: MarkersType[];
  sqlCondition: string | null;
}

export interface ManualModeDynamicMarkerInterface {
  defaultMarker: {
    isShow: boolean;
  } & MarkerSettingsInterface;
  customMarkers: CustomMarkersInterface[];
}

export interface ColorMarkerSQLInterface extends ColorByInterface {
  colorManual: ColorValuesByThemeType | null;
}

export interface FormMarkerInterface {
  type: ColorAndImageByType;
  manualMarker: MarkersType;
  byCondition: DynamicMarkerByConditionInterface;
}

export interface SQLModeDynamicMarkerInterface {
  colorMarker: ColorMarkerSQLInterface;
  formMarker: FormMarkerInterface;
}

export interface CommonSettingsDynamicMarkerInterface {
  position: RightAndLeftType;
  indent: number;
  sizeMarker: number;
}

export interface SettingsOneDynamicMarkerInterface extends CommonSettingsDynamicMarkerInterface {
  type: MarkersType;
  color: ColorValuesByThemeType | null;
  colorsByValue?: MapRecordType<ColorByItem[]> | null;
}

export interface DynamicMarkerSettingsInterface extends CommonSettingsDynamicMarkerInterface, IsShowInterface {
  settingsMarker: {
    mode: DynamicMarkerModeType;
    manual: ManualModeDynamicMarkerInterface;
    sql: SQLModeDynamicMarkerInterface;
  };
}

export interface TableIndicatorSettingsInterface
  extends DefaultIndicatorSettingsInterface,
    ColumnWidthSettingsInterface,
    SortSettingsInterface {
  totalSettings: TotalSettings;
  properties: DefaultPropertiesInterface;
  dynamicsMarkerSettings: DynamicMarkerSettingsInterface;
  indentation: IndentationInterface;
  hyperLink: MakeHyperLinksInterface;
  imagesSettings: ImagesSettingsInterface;
  hasIndentation: boolean;
  isSort: boolean;
}

export interface TableIndicatorInterface extends DefaultIndicatorInterface {
  settings: TableIndicatorSettingsInterface;
  color: ColorValuesByThemeType | null;
}

export interface FrameIndicatorInterface extends DefaultIndicatorInterface {
  settings: TableIndicatorSettingsInterface;
  color: ColorValuesByThemeType | null;
}

export interface TableDataSettings
  extends DefaultDataSettingsInterface,
    ColorByValueSettingsInterface,
    BackgroundByValueSettingsInterface {
  incisionsInHeader: TableIncisionInterface[];
  incisions: TableIncisionInterface[];
  indicators: TableIndicatorInterface[];
  orderBy: SortingInterface[];
  limitGrouping: LimitSettingInterface;
  hasAllGroupIncision: boolean;
}

export type BeatType = 'background' | 'line';

export interface TotalLocationSettingsInterface {
  isShow: boolean;
  position: RadioSelectedItem<TopAndBottomType>;
}

export interface IndentationInterface {
  top: number;
  bottom: number;
  left: number;
  right: number;
  unsetPaddings?: boolean;
  gapX?: number;
  gapY?: number;
}

export interface TotalRowSettingsInterface {
  isShow: boolean;
  location: TotalLocationSettingsInterface;
  properties: DefaultPropertiesInterface;
  overfill: boolean;
  hasIndentation: boolean;
  indentation: IndentationInterface;
  customTitle: string;
}

export interface IsBeatInterface {
  isBeat: boolean;
  color: PaletteValuesByThemeType | ColorValuesByThemeType | null;
  countUnit: number;
}

export interface TableHeaderSettingsInterface extends TotalRowSettingsInterface {
  headerBeatLine: IsBeatInterface;
}

export interface TableBodySettingsInterface {
  propertiesIncisions: DefaultPropertiesInterface;
  propertiesIndicators: DefaultPropertiesInterface;
  verticalLines: IsBeatInterface;
  horizontalLines: IsBeatInterface;
  externalBeat: IsBeatInterface;
  rowsBeat: IsBeatInterface;
  indentation: IndentationInterface;
}

export interface TableViewSettings extends StrictDefaultViewSettingsInterface {
  showVerticalLine: boolean;
  showHorizontalLine: boolean;
  isAdaptive: boolean;
  incisionBeat: BeatType[];
  headerBeat: BeatType[];
  totalBeat: BeatType[];
  headerSettings: TableHeaderSettingsInterface;
  bodySettings: TableBodySettingsInterface;
  subtotalsSettings: TotalRowSettingsInterface;
  totalRowSettings: TotalRowSettingsInterface;
}

export interface TableEventsSettings extends DefaultEventsSettingsInterface {
  isSortInHeader: boolean;
}

export type TableVisualisationType = VisualisationOptionsInterface<
  TableDataSettings,
  TableEventsSettings,
  TableViewSettings,
  DefaultBackgroundImagesSettingsType,
  DefaultSqlDataInterface,
  'table'
>;

export interface ExportDataInterface {
  visualizationId: string;
  data: SQLRequestInterface;
  exportType: ExportType;
}

export interface SortConditionColorInterface {
  dataSettings: DefaultDataSettingsInterface;
  alias: 'colorsValueBy' | 'backgroundByValueAlias';
  valueCondition: 'fontColorBy' | 'backgroundColorBy';
}

export interface getSqlRequestForGroupingRowTableInterface {
  id: string;
  columnNextIndex?: number;
  parentsChain?: string[];
  limitData?: Limit;
  incisionsInHeaderNames: string[];
  isPivotTable?: boolean;
}

export interface TemplateVisualisationActionPayload extends PageIdInterface {
  templateVisualisationId: string;
}

export enum LineAndBarIndicatorTypesEnum {
  Bar = 'bar',
  Line = 'line',
}

export interface AddVisualisationActionProps extends Partial<PositionConfigSettingsInterface> {
  visualisationType: VisualisationTypeType;
}

export interface AddTemplateVisualisationActionProps extends Partial<PositionConfigSettingsInterface> {
  data: DefaultVisualisationOptionsType;
}

/* GeometricShapes */
export interface GeometricShapesIncisionSettingsInterface
  extends DefaultIncisionSettingsInterface,
    ColumnWidthSettingsInterface,
    SortSettingsInterface {
  properties: DefaultPropertiesInterface;
  indentation: IndentationInterface;
  hyperLink: MakeHyperLinksInterface;
  imagesSettings: ImagesSettingsInterface;
  hasIndentation: boolean;
  isSort: boolean;
}

export interface GeometricShapesIncisionInterface extends DefaultIncisionInterface {
  settings: GeometricShapesIncisionSettingsInterface;
  color: ColorValuesByThemeType | null;
}

export interface GeometricShapesIndicatorSettingsInterface
  extends DefaultIndicatorSettingsInterface,
    ColumnWidthSettingsInterface,
    SortSettingsInterface {
  totalSettings: TotalSettings;
  properties: DefaultPropertiesInterface;
  dynamicsMarkerSettings: DynamicMarkerSettingsInterface;
  indentation: IndentationInterface;
  hyperLink: MakeHyperLinksInterface;
  imagesSettings: ImagesSettingsInterface;
  hasIndentation: boolean;
  isSort: boolean;
}

export interface GeometricShapesIndicatorInterface extends DefaultIndicatorInterface {
  settings: GeometricShapesIndicatorSettingsInterface;
  color: ColorValuesByThemeType | null;
}

export interface GeometricShapesDataSettings extends DefaultDataSettingsInterface {
  incisions: DefaultIncisionInterface[];
  variables: TextVariablesInterface[];
}

export interface GeometricShapesHeaderSettingsInterface extends TotalRowSettingsInterface {
  headerBeatLine: IsBeatInterface;
}

export interface RenderWidgetPropsType {
  data: {
    backgroundImagesSettings: TextBackgroundSettings;
    dataSettings: GeometricShapesDataSettings;
    events: DefaultEventsSettingsInterface;
    id: string;
    isBlock: boolean;
    isVisible: boolean;
    pageId: string;
    positionConfig: BoardPositionConfigInterface;
    sqlData: DefaultSqlDataInterface;
    viewSettings: GeometricShapesViewSettings;
    visualisationType: string;
  };
}

export interface ColorGeometricShapesSettings {
  isActive: boolean;
  geometricShapesColorBy: ColorByInterface;
  fillColor: {
    isShow: boolean;
    color: null | ColorValuesByThemeType;
  };
  lineColor: {
    isShow: boolean;
    color: null | ColorValuesByThemeType;
  };
}

export interface defaultSettingsGeometricShapes {
  type: string | null;
  lineWidth: number;
  rotation: number;
  offsetContainer: number;
  size?: number;
  styleLine?: string;
  borderRadius: number;
  pathLine?: string | null;
  vertex: number;
  lengthDotted: number;
  distanceDotted: number;
  orientation?: string;
  colorSettings: ColorGeometricShapesSettings;
}

export interface GeometricShapesViewSettings extends DefaultViewSettingsInterface, ScrollSettingsInterface {
  code: TextSettingColorInterface;
  position: TextPositionInterface;
  textProperties: DefaultPropertiesInterface;
  headerSettings: GeometricShapesHeaderSettingsInterface;
  defaultSettings: defaultSettingsGeometricShapes;
}

export type GeometricShapesVisualisationType = VisualisationOptionsInterface<
  GeometricShapesDataSettings,
  DefaultEventsSettingsInterface,
  GeometricShapesViewSettings,
  TextBackgroundSettings,
  DefaultSqlDataInterface,
  'geometricShapes'
>;
